
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'plan-card',
  props: {
    plan: Object,
    frequency: { default: 'month', type: String },
    services: Array,
  },
  emits: ['select'],
  setup(props, { emit }) {
    const price = computed(() => {
      return props.frequency == 'month'
        ? props?.plan?.monthly_price
        : parseFloat(props?.plan?.monthly_price) * 12;
    });

    const hasService = (service: any) => {
      const services = [...props?.plan?.description];
      return services.includes(service);
    };

    const choosePlan = (plan: any) => {
      emit('select', plan);
    };

    return {
      choosePlan,
      price,
      hasService,
    };
  },
});
