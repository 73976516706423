
import { defineComponent, ref, computed, onMounted } from 'vue';
import {
  Getters as PlanGetters,
  Actions as PlanActions,
} from '@/store/enums/PlanEnums';
import { useStore, mapGetters, mapActions } from 'vuex';
import PlanCard from '@/components/cards/plans/PlanCard.vue';
import _ from 'lodash';

export default defineComponent({
  name: 'overview',
  components: { PlanCard },
  data: () => ({
    current: 'month',
  }),
  async mounted() {
    await this.getPlans();
  },
  computed: {
    ...mapGetters({
      listPlans: PlanGetters.GET_PLAN,
    }),
    sortedPlans() {
      const plans = [...this.listPlans];
      return plans.sort((a, b) => {
        return parseFloat(a.monthly_price) - parseFloat(b.monthly_price);
      });
    },
    planServices() {
      const arr = this.sortedPlans.map((item) => {
        return item.description;
      });

      return _.uniq(_.flattenDeep(arr));
    },
  },
  methods: {
    ...mapActions({
      getPlans: PlanActions.PLAN,
    }),
  },
  setup() {
    const store = useStore();

    const subscribePlan = async (plan: any) => {
      console.log(plan);
    };

    return {
      subscribePlan,
    };
  },
});
